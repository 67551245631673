footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: #fff;
  padding: 1rem 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-wrap: nowrap;
  z-index: 1000;
  max-height: 10rem;
}

body {
  margin-bottom: 80px;
}

.footer-nav-btn button:hover {
  transform: scale(1.07);
}

.whatsapp-link {
  position: absolute;
  width: 60px;
  top: -80px;
  right: 30px;
  transition: 0.2s ease;
}

.whatsapp-link:hover {
  transform: scale(1.2);
}

.whatsapp-link img {
  width: 100%;
  object-fit: cover;
}

footer h3 {
  color: rgb(0, 255, 247);
}
footer h3 span,
.time {
  font-size: 1em;
  font-weight: bold;
  color: orangered;
}

.footer-content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media screen and (max-width: 767px) {
  .whatsapp-link {
    width: 50px;
    top: -70px;
    right: 2px;
  }

  .footer-content-div {
    margin-top: -2rem;
  }
}
