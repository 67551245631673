.sec-3 {
  padding: 40px 20px;
  background-color: rgb(255, 251, 243);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features-title {
  color: goldenrod;
  font-size: 30px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;

  text-align: center;
  padding: 20px 10px;
}
.sec-3-center-line {
  width: 90px;
  border-bottom: 0.1px solid rgb(129, 106, 57);
  margin: auto;
  margin-bottom: 40px;
}
.feature-list {
  width: 100%;
  max-width: 800px;
  border: 3px solid rgb(255, 199, 0);
  padding: 1rem;
  background-color: black;
  color: rgb(215, 196, 154);
  margin: 2rem auto;
  border-radius: 1rem;
  box-shadow: 0 0 20px 10px rgba(44, 44, 44, 0.3);
  letter-spacing: 1px;
}

.sec-3 ul {
  list-style-type: none;
  padding: 0;
}

.sec-3 li {
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.more-content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  color: black;
  width: 70%;

  margin-top: 4rem;
  padding: 16px;
  border-radius: 0.7rem;
}
.more-content-div a button {
  background-color: rgb(22, 201, 67);
  animation: none;
  box-shadow: none;
  border-radius: 0.5rem;
}

.more-content1 {
  font-size: 30px;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
  padding: 20px;
}

.brochureImg-div {
  width: 100%;
}
.brochure-img {
  width: 100%;
  object-fit: cover;
}
.pricing-btn {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-pdf {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none; /* Set your desired background color */
  color: #ffffff; /* Set your desired text color */
  border-radius: 5px;
  border: 2px solid; /* Set your desired border color */
  cursor: pointer;
  transition: transform 0.5s ease, letter-spacing 0.5s ease;
}

.course-pdf:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  color: #ffffff;
  transform: translateY(10%);
  letter-spacing: 1px;
}

.offer-button {
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .sec-3 {
    padding: 40px 10px;
  }

  .feature-list {
    width: 100%;
  }

  .more-content-div {
    width: 100%;
    text-align: center;
    margin-top: 0;
    padding: 0;
  }
  .brochureImg-div {
    width: 100%;
  }
  .more-content1 {
    font-size: 28px;

    margin-top: 0;

    padding: 20px 0;
  }
}
