.counter-div{
    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:white;
    padding: 40px 40px 0px 40px;
}
.numberCardview {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    flex-direction: column;
    background-color: black;
    border: 1px solid #ccc;
    border-radius: 16px;
    width: 350px;
    height: 180px;
    padding: 20px;
    margin: 20px;
    text-align: center;
    border: 3px solid goldenrod;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .numberCardview p {
    font-size: 25px;
    font-weight:700;
    color: goldenrod;
    margin-bottom: 10px;
  }
  
  .numberCardview h1 {
    font-size: 36px;
    color: bisque;
    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center

  }
  

  
  
  .numberCardview {
    transition: transform 0.3s ease-in-out;
  }
  
  .numberCardview:hover {
    transform: scale(1.05);
  }
  @media screen and (max-width: 720px) {
    .numberCardview {
        width: 100%;
        padding: 20px 10px;
    }
    .counter-div{
      flex-direction: column;
      padding: 0 10px;
    }
  }