.sec-1-div {
  padding: 50px 20px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.logo-img {
  width: 120px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.title-div {
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.company-name {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 2px;
  color: white;
}
.company-quote {
  font-size: 23px;
  color: rgb(215, 196, 154);
}

.quotes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 3rem auto;
  width: 60%;
  margin-bottom: 70px;
}
.quotes-center-line {
  width: 10rem;
  border-bottom: 0.1px solid orangered;
  margin: 20px 0;
}
.motto-quote {
  position: relative;
  color: rgb(215, 196, 154);
  font-size: 1.5em;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}
.quotes-center {
  position: absolute;
  left: 50%;
  top: -3.7%;
  text-align: center;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  background-color: crimson;
  padding: 0.5rem;
  border-radius: 4rem;
}

.quotes-1 {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}

.quotes-2 {
  box-sizing: border-box;
  color: goldenrod;
  background-color: black;
  padding: 30px;
  font-family: "Fjalla One", sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1.5px;
  border-radius: 1rem;
  box-shadow: rgba(53, 51, 48, 0.48) 0px 0px 50px 2px;
  line-height: 40.6224px;
  text-align: center;
  border: 1px solid rgb(255, 199, 0);
}

.title-col {
  font-size: 35px;
  color: orangered;
}

.course-main-div {
  position: relative;
  margin: auto;
  background-color: black;
  border-radius: 1rem;
  border: 1px solid rgb(255, 199, 0);
  padding: 4rem 2rem;
  width: 90%;
  box-shadow: rgba(53, 51, 48, 0.48) 0px 0px 50px 2px;
  margin-bottom: 50px;
}
.courese-inner-div {
  display: flex;
  flex-wrap: wrap;
}

.tick-sym {
  border-radius: 50%;
  margin-right: 10px;
}

.left-div,
.right-div {
  flex: 1;
  padding: 20px;
}

.center-line {
  width: 2px;
  background-color: #333;
  margin: 0 20px;
}

.left-title,
.right-title {
  color: goldenrod;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.left-div ul,
.right-div ul {
  list-style-type: none;
  padding: 0;
}

.left-div li,
.right-div li {
  color: rgb(215, 196, 154);
  font-size: 16px;
  padding: 10px 0;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-bottom: 5px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}

.offer-end-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.offer-end-main h3 {
  margin-bottom: 10px;
}

.offer-end-div {
  display: flex;
}

.time-box {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  background-color: #333333;
  padding: 5px 20px;
  border-radius: 5px;
  text-align: center;
  border: 2px solid white;
  color: bisque;
}

.min,
.sec {
  color: white;
  font-size: 1.5em;
}
.minutes,
.seconds {
  font-size: 3em;
  font-weight: 400;
}
.offer-timer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin: 0 auto;

  padding: 5px;
}

.offer-button button {
  font-weight: bold;
  padding: 20px 40px;
  margin-top: 10px;
  animation: anime 2s linear infinite;

  background: linear-gradient(-45deg, #fc6076, #ff9a44, #ef9d43, #e75516);
  z-index: 1;
}

@keyframes anime {
  0% {
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 40px rgba(255, 165, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  }
}

@media screen and (max-width: 900px) {
  .sec-1-div {
    padding: 50px 10px;
  }

  .quotes {
    width: 100%;
  }
  .company-name {
    font-size: 35px;
    letter-spacing: 3px;
    margin-top: 5px;
  }
  .company-quote {
    font-size: 18px;
  }
  .quotes-center {
    position: absolute;
    left: 50%;
    top: -1.9%;
    text-align: center;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    background-color: crimson;
    padding: 0.5rem;
    border-radius: 4rem;
  }
  .quotes-2 {
    box-sizing: border-box;
    color: goldenrod;
    background-color: black;
    padding: 10px 5px;
    letter-spacing: 1.5px;
    font-size: 17px;

    line-height: 30.6224px;
  }

  .title-col {
    font-size: 25px;
  }

  .motto-quote {
    font-size: 1.4em;
  }

  .course-main-div {
    width: 95%;
    padding: 3rem 0.5rem 2rem 2rem;
  }
  .courese-inner-div {
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .left-div,
  .right-div {
    flex: 1;
    padding: 0;
  }

  .tick-sym {
    margin-right: 10px;
  }

  .center-line {
    width: 45%;
    border-bottom: 1px solid white;
    margin: 15px auto;
  }

  .offer-timer-div {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .offer-button button {
    padding: 15px 30px;
    margin-top: 3rem;
  }
}
