.sec-2 {
  padding: 60px 20px;
}
.video-titles-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.yt-title1 {
  color: goldenrod;
  font-size: 30px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
}
.yt-title2 {
  color: rgb(207, 128, 68);
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Oswald", sans-serif;
}

.center-line-2 {
  width: 10%;
  border-bottom: 0.1px solid rgb(113, 111, 107);
}

.video-div {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 75%;
  height: 60vh;
  margin: auto;
}

.video-div iframe {
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  box-shadow: 0 0 20px 10px rgba(44, 44, 44, 0.3);
}

@media screen and (max-width: 900px) {
  .yt-title1 {
    font-size: 1.3rem;
  }

  .yt-title2 {
    font-size: 1.2rem;
  }

  .center-line-2 {
    width: 25%;
  }
  .video-div {
    width: 95%;
    height: 40vh;
  }
}
