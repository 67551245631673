.freecontent-div {
  background-color: black;
  min-height: 15rem;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.congrats {
  font-size: 1.5rem;
  color: goldenrod;
  font-weight: bold;
}

.free-title {
  font-size: 1.5rem;
  text-align: center;
}

.free-title span {
  color: goldenrod;
  font-weight: bold;
}

.freebtn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.freebtn a button {
  padding: 20px 30px;
  border-radius: 4px;
  font-size: 1em;
  color: black;
  font-weight: bold;
  cursor: pointer;
  background: rgb(251, 250, 237);
  z-index: 1;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}
.freebtn a button:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .free-title {
    font-size: 1rem;
  }

  .freebtn {
  }
}
