.popupdiv {
  display: flex;

  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 110vh;
  background-color: rgba(38, 37, 37, 0.9);
  backdrop-filter: blur(4px);
  transform: translate(-50%, -50%);
  z-index: 1099;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.contactPage {
  width: 40%;
  background-color: black;
  border: 1px solid goldenrod;
  box-shadow: 0 0 20px 10px rgb(12, 12, 12);
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}
.contactPage h1 {
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  width: 90%;
}
.contactPage span {
  color: goldenrod;
}
.contactform {
  margin-top: 0px;
  width: 80%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}
.email,
.fullname {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 40px 10px 10px;
  border: 1.5px solid black;
  border-radius: 4px;
  font-size: 16px;

  letter-spacing: 1px;
  background-color: rgb(255, 251, 243);
  color: black;
}
.email:focus,
.fullname:focus {
  outline: none;
}

.submitbtn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #036eb5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  animation: none;
  box-shadow: none;
  transition: transform 0.2s ease, letter-spacing 0.2s ease;
}

.submitbtn:hover {
  background-color: #2980b9;
  color: #ffffff;
  transform: translateY(10%);
  letter-spacing: 1px;
}
.close-btn {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  animation: none;
  box-shadow: none;
  padding: 0;
  background: none;
  cursor: pointer;
}
.close-btn img:hover {
  background-color: red;
}
.close-btn img {
  width: 43px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
}

.popup-wish {
  text-wrap: nowrap;
  margin-bottom: 20px;
  text-align: center;
  padding: 15px;
  border: 2px solid;
  border-color: gold;
  border-radius: 10px;
  color: black;
  background-color: white;
}

.wish {
  color: rgb(249, 37, 0);
}

.popup-wish p {
  font-weight: bold;
  color: rgb(249, 37, 0);
}

@media screen and (max-width: 767px) {
  .contactPage {
    width: 100%;
    box-shadow: 0 0 20px 10px rgba(12, 12, 12, 0.3);
  }

  .contactPage h1 {
    font-size: 18px;
  }
  .contactform {
    width: 100%;
  }
  .close-btn {
    top: -50px;
    right: -5px;
    width: 30px;
  }
  .close-btn img {
    width: 40px;
  }

  .popup-wish {
    text-wrap: wrap;
  }

  .popup-wish h1 {
    font-size: 5px;
  }
}
