.popupdiv2 {
  display: flex;

  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 110vh;
  background-color: rgba(38, 37, 37, 0.9);
  backdrop-filter: blur(4px);
  transform: translate(-50%, -50%);
  z-index: 1099;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.contactPage {
  width: 40%;
  background-color: black;
  border: 1px solid goldenrod;
  box-shadow: 0 0 20px 10px rgb(12, 12, 12);
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

.contactPageTitle {
  margin-bottom: 2rem;
}

.freebutn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.freebutn a button {
  padding: 20px 30px;
  border-radius: 4px;
  font-size: 1em;
  color: black;
  font-weight: bold;
  cursor: pointer;
  background: rgb(251, 250, 237);
  z-index: 1;
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
}
.freebutn a button:hover {
  transform: scale(1.1);
}
.close-btn {
  position: absolute;
  top: -50px;
  right: -50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  animation: none;
  box-shadow: none;
  padding: 0;
  background: none;
  cursor: pointer;
}
.close-btn img:hover {
  background-color: red;
}
.close-btn img {
  width: 43px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
}

@media screen and (max-width: 767px) {
  .contactPage {
    width: 100%;
    box-shadow: 0 0 20px 10px rgba(12, 12, 12, 0.3);
  }

  .contactPage h1 {
    font-size: 18px;
  }

  .close-btn {
    top: -50px;
    right: -5px;
    width: 30px;
  }
  .close-btn img {
    width: 40px;
  }
}
